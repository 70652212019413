import Home from './pages/Home';

import {
    HOME_ROUTE,
} from './utils/constants'


export const publicRoutes = [
    {
        path: HOME_ROUTE,
        element : <Home />,
    },
]