import { useTranslation } from 'react-i18next'

import SocialMedia from "./SocialMedia";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-[#868686] text-white">
            <div className="container">
                <div className="flex justify-between py-12 max-[767.98px]:flex-col">
                    <div className="max-[767.98px]:mb-10">
                        <h4 className="text-2xl font-bold mb-4">{ t('contacts.title') }</h4>
                        <p className="max-w-lg mb-8">{ t('contacts.description') }</p>
                        <SocialMedia />
                    </div>
                    <div className="flex flex-col">
                        <div className="text-xl mb-4">{ t('contacts.head') }</div>
                        <div className="mb-2">{ t('contacts.address') }</div>
                        <a href="mailto:Info@yerzhanov.kz" className="mb-2">Info@yerzhanov.kz</a>
                        <a href="mailto:urazaly.erzhanov@gmail.com" className="mb-2">urazaly.erzhanov@gmail.com</a>
                        <a href="tel:+77010270009">{ t('contacts.phone') } +7 701 027 00 09</a>
                    </div> 
                </div> 
            </div>
            <div className="py-3 bg-black">
                <div className="container">
                    <div className="text-center text-sm">
                        © { t('contacts.bottom') }
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;