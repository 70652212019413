import { useTranslation } from 'react-i18next';

import { createMarkup } from './Campaign';

const Biography = () => {
    const { t } = useTranslation();

    return (
        <section className="py-24">
            <div className="container">
                <h2 className='mb-8 text-center'>{ t('biography.mainTitle') }</h2>
                <div className="text-xl font-medium mb-4">{ t('biography.subtitle') }</div>
                <ul>
                    {
                        t('biography.list', { returnObjects: true }).map((item, index) => {
                            return (
                                <li
                                    key={item + index}
                                >
                                    { item }
                                </li>
                            )
                        })
                    }
                </ul>
                <div className='mt-16'>
                    <h2 className='text-center mb-10'>{ t('biography.about') }</h2>
                    <div className="grid grid-cols-1 gap-8">
                        {
                            t('biography.aboutList', { returnObjects: true } )
                            .map(({ title, description }, index) => {
                                return (
                                    <div
                                        key={title + index} 
                                        className="flex flex-col"
                                    >
                                        <h5 className='mb-2 text-xl font-medium'>{title}</h5>
                                        <p dangerouslySetInnerHTML={createMarkup(description)}></p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Biography;