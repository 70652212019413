import { useTranslation } from 'react-i18next'

import motion from '../../utils/motionUtil'
import Collapse, { Panel } from "rc-collapse"
import 'rc-collapse/assets/index.css'

import '../../styles/components/sections/Campaign.css'

export function createMarkup(text) { return {__html: text}; };

const Campaign = () => {
    const { t } = useTranslation();

    return (
        <section className="py-24">
            <div className="container">
                <h2 className="mb-4 text-center">
                    { t("campaign.title.0") }
                </h2>
                <div className="text-4xl font-medium uppercase mb-8 text-center">
                    { t("campaign.title.1") }
                </div>
                <p className='mb-16 text-center max-w-6xl mx-auto'>
                    { t("campaign.description") }
                </p>
                <Collapse 
                    accordion={false}
                    openMotion={motion}
                >
                        { 
                            t('accordion', { returnObjects: true }).map((accordion, index) => {
                                return (
                                    <Panel 
                                        key={index}
                                        header={accordion.title} 
                                        headerClass="accordion__header"
                                    >
                                        <p className='text-lg max-w-4xl text-black' dangerouslySetInnerHTML={createMarkup(accordion.description)}></p>
                                    </Panel>
                                )
                            })
                        }
                </Collapse>
            </div>
        </section>
    )
};

export default Campaign;