import Hero from '../components/sections/Hero';
import Campaign from '../components/sections/Campaign';
import Interviews from '../components/sections/Interviews';
import Biography from '../components/sections/Biography';
import Footer from '../components/Footer'


const Home = () => { 

    return (
        <>  
            <main>
                <Hero />
                <Campaign />
                <Interviews />
                <Biography />
            </main>
            <Footer/>
        </>
    )
}

export default Home