import { useTranslation } from 'react-i18next'

const interviews = [
    'Hj3evLYwInc',
    'zIWNy1kow7A',
    'tlOj9g5Pcvw',
];

const Interviews = () => {
    const { t } = useTranslation();

    return (
        <section className="py-24 bg-gray-100">
            <div className="container">
                <h2 className="mb-16 text-center">{ t('interview') }</h2>
                <div className="grid grid-cols-3 gap-4  max-[991.98px]:grid-cols-2 max-[767.98px]:grid-cols-1">
                    {
                        interviews.map(interview => {
                            return (
                                <iframe
                                    key = {interview}
                                    className="w-full rounded-xl"
                                    height='315'
                                    loading="lazy"
                                    title="Интервью"
                                    src={"https://www.youtube.com/embed/" + interview}
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen
                                ></iframe>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Interviews;