import { React, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";

import './styles/global/global.css'
import './i18n'; 

import { publicRoutes } from './routes'
import Home from './pages/Home';
import Root from './pages/Root';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route 
            path='/' 
            element={<Root/>}
            errorElement={<Home />}
        >
            {
                publicRoutes.map(({path, element}) => {
                    return (
                        <Route key={path} path={path} element={element} />
                    )
                })
            }
        </Route>
    )
);

root.render(
    <StrictMode>
        <RouterProvider router={router} />
    </StrictMode>
);