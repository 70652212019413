const links = [
    {
        icon: '/icons/instagram.png',
        link: 'https://instagram.com/urazaly_yerzhanov?igshid=YmMyMTA2M2Y=',
    },
    {
        icon: '/icons/whatsapp.png',
        link: 'https://api.whatsapp.com/send?phone=77010270009',
    },
    {
        icon: '/icons/youtube.png',
        link: 'https://youtube.com/@elgeqaitaru1775',
    },
    {
        icon: '/icons/facebook.png',
        link: 'https://www.facebook.com/profile.php?id=100009384826385&mibextid=LQQJ4d',
    },
];

const SocialMedia = () => {
    return (
        <ul className="social__media">
            {
                links.map(({icon, link}, i) => {
                    return (
                        <li
                            className="social__li"
                            key={i}
                        >
                            <a 
                                href={link} 
                                target="_blank" 
                                rel="noreferrer noopener"
                                className="social__link"
                            >
                                <img src={icon} alt="icon" width='30' height='30' />
                            </a>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default SocialMedia;