import { useTranslation } from 'react-i18next';

import '../../styles/components/sections/Hero.css'

import SocialMedia from '../SocialMedia';

const Hero = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
    }; 


    return (
        <section className='relative z-10 overflow-hidden min-h-screen flex items-center py-36 text-white bg-gray-gradient  max-[1700px]:py-24 max-[991.98px]:flex-col max-[767.98px]:py-10 max-[767.98px]:min-h-0'>
            <div className="container relative z-[3]">
                <div className="flex mb-10 max-[767.98px]:justify-between">
                    <a href="https://elgeqaitaru.kz" target="_blank" rel='noreferrer noopener'>
                        <img src="/img/logo.svg" alt="logo" width="97" height="45" className='mr-5' />
                    </a>
                    <div className="flex items-center text-xl font-semibold mr-5">
                        <button 
                            onClick={() => changeLanguage('kz')}
                            className={i18n.language === 'kz' ? 'text-red' : ''}
                        >каз</button>
                        <div className="h-full w-px mx-2 bg-white"></div>
                        <button 
                            onClick={() => changeLanguage('ru')}
                            className={i18n.language === 'ru' ? 'text-red' : ''}
                        >рус</button>
                    </div>
                    <div className="max-[767.98px]:hidden">
                        <SocialMedia />
                    </div>
                </div>
                <div className="text-5xl text-center max-w-3xl font-light uppercase mt-24 max-[767.98px]:text-2xl max-[767.98px]:mt-36">
                    <div>
                        ЕЛГЕ <span className='font-bold'>ҚАЙТАРАМЫН!</span>
                    </div>
                    <div>
                        Верну народное - <span className='font-bold'>Народу!</span>
                    </div>
                </div>
                <div className="flex items-center justify-center mt-24 max-[767.98px]:flex-col max-[767.98px]:mt-14">
                    <div className="flex items-end max-[767.98px]:items-center">
                        <h1 className='font-semibold uppercase mr-5 text-right'> 
                            <span className='font-bold text-6xl max-[767.98px]:text-4xl'>{ t('surname') }</span><br />
                            <span className='text-5xl font-semibold max-[767.98px]:text-3xl'>{ t('name') }</span>
                        </h1>
                        <img src="/icons/check.svg" alt="check" width="160" height="140" className=' max-[1440px]:w-[150px] max-[767.98px]:w-20 ' />
                    </div>
                    <div className="font-light text-3xl max-w-xl text-left ml-4 max-[767.98px]:ml-0 max-[767.98px]:mt-4 max-[767.98px]:text-xl max-[767.98px]:text-center">
                        { t("districts.0") }
                        <br />
                        { t("districts.1") }
                    </div>
                </div>
                {/* <div className="mt-10 flex justify-center items-center min-[768px]:hidden">
                    <SocialMedia />
                </div> */}
            </div>
            <div className="absolute top-0 left-0 w-full h-1/2 bg-gradient-to-b from-[rgba(0,0,0,0.9)] to-transparent z-[2]"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-[rgba(0,0,0,0.9)] to-transparent z-[2]"></div>
            <img className="absolute bottom-0 right-0 h-full object-contain object-right max-[767.98px]:object-cover " src="/img/deputat.png" alt="ЕРЖАНОВ УРАЗАЛЫ" width="1168" height="1268" />
        </section>
    )
}

export default Hero;