Object.defineProperty(exports, "__esModule", { value: true });
var getCollapsedHeight = function () { return ({ height: 0, opacity: 0 }); };
var getRealHeight = function (node) { return ({ height: node.scrollHeight, opacity: 1 }); };
var getCurrentHeight = function (node) { return ({ height: node.offsetHeight }); };
var skipOpacityTransition = function (_, event) {
    return event.propertyName === 'height';
};
var collapseMotion = {
    motionName: 'rc-collapse-motion',
    onEnterStart: getCollapsedHeight,
    onEnterActive: getRealHeight,
    onLeaveStart: getCurrentHeight,
    onLeaveActive: getCollapsedHeight,
    onEnterEnd: skipOpacityTransition,
    onLeaveEnd: skipOpacityTransition,
    motionDeadline: 500,
    leavedClassName: 'rc-collapse-content-hidden',
};
exports.default = collapseMotion;
